h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
}
p {
  text-decoration: none;
  text-align: justify;
}
ul {
  list-style: circle;
}
